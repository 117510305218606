<template>
  <div v-if="!multiple">
    <v-checkbox
      class="ma-0 top-list"
      :color="color"
      :prepend-icon="icon"
      :label="$t(label)"
      v-model="value"
      :readonly="fieldReadonly"
    >
      <v-tooltip slot="append" bottom v-if="$t(description)">
        <template v-slot:activator="{ on }">
          <v-icon slot="activator" v-on="on">mdi-help-circle</v-icon>
        </template>
        <span>{{ $t(description) }}</span>
      </v-tooltip>
    </v-checkbox>
  </div>
  <div v-else>
    <div class="v-text-field">
      <v-layout row>
        <v-flex xs12>
          <v-subheader
            light
            class="v-label v-label--active"
            :style="{'padding-left': (icon) ? '56px': '12px'}"
            style="margin-bottom: -30px; width:100%">
              {{ $t(label) }}
          </v-subheader>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex v-if="icon" style="width: 24px; margin-left: 12px;">
          <v-layout fill-height align-center justify-start>
            <v-icon>{{ icon }}</v-icon>
          </v-layout>
        </v-flex>
        <v-flex style="width: calc(100% - 72px)">
          <ul
            class="top-list"
            :class="{pl0: icon}"
          >
            <li
              v-for="(item, index) in items"
              :key="index"
            >
              <v-checkbox
                class="ma-0"
                :color="item.color"
                :prepend-icon="item.icon"
                :label="$t(item.label)"
                :value="item.value"
                v-model="value"
                :readonly="fieldReadonly"
              />
            </li>
          </ul>
        </v-flex>
        <v-flex v-if="$t(description)" style="width: 24px; margin-right: 12px;">
          <v-layout fill-height align-center justify-end>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon class="ml-12" slot="activator" v-on="on">mdi-help-circle</v-icon>
              </template>
              <span>{{ $t(description) }}</span>
            </v-tooltip>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import _ from '@/misc/lodash';
import baseTextInput from '@/mixins/baseTextInput';

export default {
  mixins: [
    baseTextInput,
  ],
  props: {
    label: {
      type: String,
      default: 'fields.checkBox',
    },
    description: {
      type: String,
      default: 'fields.checkBoxTooltip',
    },
    /**
     * If want multiple value items need to be an array like
     *
     * {
     *   label: string,
     *   value: number|string
     *   color: string // optional
     *   icon: string // optional
     * }
     */
    items: {
      type: Array,
      default: null,
    },
    default: {
      type: [Boolean, String, Array],
      default: false,
    },
  },
  computed: {
    multiple() {
      return Array.isArray(this.items);
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit('change', this.value);
      },
      deep: true,
    },
  },
  /**
   * Handle type for multiple value in components not in mixin
   * to prevent site effect with other components
   */
  created() {
    // Default value is handle in baseTextInput mixin
    let { value } = this;

    if (this.multiple) {
      // Normalize value if multiple is enable
      if (!Array.isArray(value)) {
        if (!_.isEmpty(value)) {
          value = [value];
        } else {
          value = [];
        }
      }
    } else if (Array.isArray(value)) {
      // Normalize value if multiple is disable and receive an array
      ([value] = value);
    }

    this.value = value;
  },
};
</script>

<style lang="scss">
.top-list {
  list-style-type: none;
  list-style: none;
  width: 100%;
  &.pl0 {
    padding-left: 0px !important;
  }
  .v-input__control {
    width: 100%;
  }
  .v-messages {
    display: none;
  }
}
</style>
